@import "../../../node_modules/bulma/sass/utilities/initial-variables";
@import "../../../node_modules/bulma/sass/utilities/functions";
@import "../../../node_modules/buefy/src/scss/utils/_all";
@import "../../../node_modules/bulma";
@import "../../../node_modules/buefy/src/scss/buefy.scss";
//@import "../../../node_modules/buefy/src/scss/components/_dialog";
//@import "../../../node_modules/buefy/src/scss/components/_dropdown";
//@import "../../../node_modules/buefy/src/scss/components/_form";
//@import "../../../node_modules/buefy/src/scss/components/_checkbox";
//@import "../../../node_modules/buefy/src/scss/components/_icon";
//@import "../../../node_modules/buefy/src/scss/components/_loading";
//@import "../../../node_modules/buefy/src/scss/components/_modal";

@import "./customize.scss";
[v-cloak] { display: none !important; }

